import React from 'react'
import "./Footer.css"
import logoP from "../../Assets/Images/logo_pay_footer.png"
const Footer = () => {
    return (
        <div className='container-fluid' style={{ backgroundColor: "#00494C" }}>
            <div className='footer_div1 py-4'>
                <div className='pt-3'>
                <div className='logo_footer'>
                           <a href="/"><img src={logoP} alt="Tusk Consulting Group LLC | Grango LLC | Orange Raccoon Funding LLC"/></a> 
                        </div>
                    <p className='footer_p'>Copyright © 2024 TV Internet Mobile Deals</p>
                </div>
                <div className=''>
                    <p className='footer_p'>Read our <a href="/privacy-policy">Privacy Policy</a> or <a href="/terms-conditions">Terms & Conditions </a>Here</p>
                </div>
            </div>
        </div>
    )
}

export default Footer